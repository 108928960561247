
// Runfont import

@font-face {
  font-family: 'Runfont';
  src: url('@{icon-font-path}RunFont.eot');
  src: url('@{icon-font-path}RunFont.eot?#iefix') format('embedded-opentype'),
       url('@{icon-font-path}RunFont.woff') format('woff'),
       url('@{icon-font-path}RunFont.ttf') format('truetype'),
       url('@{icon-font-path}RunFont.svg#runfont') format('svg');
}

.rf {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Runfont';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.rf-user 					{ &:before { content:'\0041'; } }
.rf-edit 					{ &:before { content:'\0042'; } }
.rf-delete 					{ &:before { content:'\0043'; } }
.rf-download 				{ &:before { content:'\0044'; } }
.rf-list 					{ &:before { content:'\0045'; } }
.rf-stats 					{ &:before { content:'\0046'; } }
.rf-runners 				{ &:before { content:'\0047'; } }
.rf-accreditations 			{ &:before { content:'\0048'; } }
.rf-print 					{ &:before { content:'\0049'; } }
.rf-messages 				{ &:before { content:'\004a'; } }
.rf-freeofcharge 			{ &:before { content:'\004b'; } }
.rf-discounts 				{ &:before { content:'\004c'; } }
.rf-polls 					{ &:before { content:'\004d'; } }
.rf-invoices 				{ &:before { content:'\004e'; } }
.rf-payments 				{ &:before { content:'\004f'; } }
.rf-admin 					{ &:before { content:'\0050'; } }
.rf-countries 				{ &:before { content:'\0051'; } }
.rf-startnumber 			{ &:before { content:'\0052'; } }
.rf-langs 					{ &:before { content:'\0053'; } }
.rf-accreditation-genres 	{ &:before { content:'\0054'; } }
.rf-runs 					{ &:before { content:'\0055'; } }
.rf-paid 					{ &:before { content:'\0056'; } }
.rf-users 					{ &:before { content:'\0057'; } }
.rf-teams 					{ &:before { content:'\0058'; } }
.rf-zones 					{ &:before { content:'\0059'; } }
.rf-news 					{ &:before { content:'\005a'; } }
.rf-runtypes 				{ &:before { content:'\0061'; } }
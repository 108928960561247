
#titlebar {
	padding-top: 10px;
	padding-bottom: 10px;
}

.header-title {
	> h2 {
		margin-top: 0;
		margin-bottom: 10px;
		font-weight: bold;
		color: @brand-primary;
	}
}
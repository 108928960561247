.notie-transition {
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.notie-background-success {
	background-color: @brand-success !important;
}

.notie-background-warning {
	background-color: @brand-warning !important;
}

.notie-background-error {
	background-color: @brand-danger !important;
}

.notie-background-info {
	background-color: @brand-info !important;
}

#notie-alert-outer, #notie-confirm-outer, #notie-input-outer, #notie-select-outer, #notie-date-outer {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999999;
	height: auto;
	width: 100%;
	display: none;
	text-align: center;
	cursor: pointer;
	font-size: 16px;
	-o-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
	-ms-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}
@media ( max-width: @grid-float-breakpoint-max ) {
	#notie-alert-outer, #notie-confirm-outer, #notie-input-outer, #notie-select-outer, #notie-date-outer {
		font-size: 14px;
	} 

}

#notie-alert-inner {
	padding: 10px;
	display: table-cell;
}

#notie-alert-content {
	max-width: 900px;
	margin: 0 auto;
}

#notie-alert-text {
	color: #FFFFFF;
}

#notie-confirm-outer {
	cursor: default;
}

#notie-confirm-inner, #notie-input-inner, #notie-select-inner {
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	display: block;
	cursor: default;
	background-color: #4D82D6;
}

#notie-confirm-text {
	color: #FFFFFF;
}

#notie-confirm-text-yes {
	color: #FFFFFF;
}

#notie-confirm-text-no {
	color: #FFFFFF;
}

#notie-confirm-yes, #notie-confirm-no, #notie-input-no, #notie-input-yes {
	float: left;
	height: 50px;
	line-height: 50px;
	width: 50%;
	cursor: pointer;
	background-color: #57BF57;
}

#notie-confirm-no, #notie-input-no {
	float: right;
	background-color: #E1715B;
}

#notie-confirm-background, #notie-input-background, #notie-select-background, #notie-date-background {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999980;
	height: 100%;
	width: 100%;
	display: none;
	background-color: #FFFFFF;
	opacity: 0;
}

/* INPUT */
#notie-input-outer {
	cursor: default;
}

#notie-input-field {
	display: block;
	box-sizing: border-box;
	height: 55px;
	width: 100%;
	text-align: center;
	outline: 0;
	border: 0;
	background-color: #FFFFFF;
	font-family: inherit;
	font-size: 16px;
}

@media (max-width: @grid-float-breakpoint-max) {
	#notie-input-field {
		font-size: 14px;
	}
}

#notie-input-text {
	color: #FFFFFF;
}

#notie-input-text-yes {
	color: #FFFFFF;
}

#notie-input-text-no {
	color: #FFFFFF;
}

#notie-select-outer {
	top: auto;
	bottom: 0;
	cursor: default;
}

#notie-select-text {
	color: #FFFFFF;
}

#notie-select-choices, .notie-select-choice {
	background-color: #57BF57;
}

.notie-select-choice {
	height: 50px;
	line-height: 50px;
	color: #FFFFFF;
	cursor: pointer;
}

#notie-select-cancel {
	height: 60px;
	line-height: 60px;
	color: #FFFFFF;
	cursor: pointer;
	background-color: #A0A0A0;
}

.notie-select-choice-bottom-border {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

#notie-date-outer {
	background-color: #4D82D6;
	color: #FFFFFF;
	cursor: default;
}

#notie-date-selector {
	margin: 0 auto;
	max-width: 900px;
	padding-left: 10px;
	padding-right: 10px;
}

.notie-date-up, .notie-date-down {
	height: 50px;
	float: left;
	width: 33.333333%;
	cursor: pointer;
}

.notie-date-arrow, .notie-date-arrow-up, .notie-date-arrow-down {
	height: 40px;
	width: 40px;
	background-size: 40px 40px;
	margin: 0 auto;
	margin-top: 5px;
}

.notie-date-text {
	height: 50px;
	line-height: 50px;
	float: left;
	width: 33.333333%;
}

#notie-date-yes, #notie-date-no {
	float: left;
	width: 50%;
	height: 50px;
	line-height: 50px;
	color: #FFFFFF;
	background-color: #57BF57;
	cursor: pointer;
}

#notie-date-no {
	background-color: #E1715B;
}
